








































































































































import Component, { mixins } from 'vue-class-component';

import WidgetMixins from '../../../mixins/WidgetMixins';
import DialogMixins from '../../../mixins/DialogMixins';
import { Getter, namespace } from 'vuex-class';
import { ILogs, ILogsGraph, IPaginate, IPayments } from '@/types/types';
import { dateFormat, truncateContent, loadWidget } from '@/utils/helpers';
import { Watch } from 'vue-property-decorator';
import moment from 'moment';

const paymentsModule = namespace('payments');

@Component({
  name: 'Index',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    DoubleDatePicker: loadWidget('widgets/DoubleDatePicker'),
  },
  filters: {
    dateFormat,
    truncateContent,
  },
})
export default class Index extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @paymentsModule.Getter('getPagination') paginate!: IPaginate & {
    limit: number;
  };
  @paymentsModule.Getter('getPaymentList') payments!: IPayments[];
  @paymentsModule.Getter('getLogsGraph') logsGraph!: Array<ILogsGraph>;

  menu = false;
  renderChart = false;
  options: IPaginate = {
    itemsPerPage: 15,
    page: 1,
    total: 0,
  };
  headers: Array<{ text: string; value: string; align?: string }> = [
    {
      text: 'Date',
      value: 'createdAt',
    },

    {
      text: 'Email',
      value: 'email',
    },
    {
      text: 'Recipient',
      value: 'recipient',
    },
    {
      text: 'Type',
      value: 'type',
    },
    {
      text: 'Status',
      value: 'status',
    },
    {
      text: 'Content',
      value: 'content',
    },
    // { text: 'Action', value: 'action' },
  ];

  chartOptions: any = {
    chart: {
      zoom: { enabled: false },
      toolbar: { show: false },
      width: '100%',
      type: 'bar',
      height: '400px',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10%',
        endingShape: 'rounded',
      },
    },
    colors: ['rgb(7, 143, 60)'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 3,
      colors: ['transparent'],
    },
    xaxis: {
      axisBorder: {
        show: true,
      },
      labels: {
        show: true,
        styles: {
          cssClass: 'ft font-weight-medium',
        },
      },
      axisTicks: { show: true },
      categories: [],
    },
    grid: {
      show: false,
    },
    yaxis: {
      axisBorder: { show: false },
      labels: {
        show: true,
        styles: {
          cssClass: 'ft font-weight-medium',
        },
        formatter: (value: number) => {
          return value.toFixed(0);
        },
      },
      axisTicks: { show: false },
    },
    fill: {
      opacity: 1,
    },
  };

  series: Array<{ name: string; data: number[] }> = [];

  dateFilter = '';
  page = 1;

  @Watch('page')
  onOptionsChange(page: number): void {
    this.$store.dispatch('log/list', {
      page: page ?? 1,
      limit: this.paginate.limit ?? 15,
      query: this.dateFilter,
    });
  }

  @Watch('logsGraph')
  onLogsGraphChange(data: Array<ILogsGraph>): void {
    this.chartOptions = {
      ...this.chartOptions,
      xaxis: {
        ...this.chartOptions.xaxis,
        categories: data.map(({ date }) => date),
      },
    };
    this.series = [
      {
        name: "Total SMS's Delivered",
        data: data.map(({ total_count }) => total_count),
      },
    ];
  }
  filterLogsByDate(date: string): void {
    this.page = 1;
    this.dateFilter = `&date=${date}`;
    this.$store.dispatch('log/list', {
      page: this.page,
      limit: this.paginate.limit,
      query: this.dateFilter,
    });
  }

  created(): void {
    this.$nextTick(() => {
      this.renderChart = true;
    });
    this.page = 1;
    this.dateFilter = `&date=${moment()
      .subtract(5, 'month')
      .format('YYYY-MM-DD')},${moment().format('YYYY-MM-DD')}`;
    this.$store.dispatch('payments/listPayments', {
      page: this.page,
      limit: 15,
      query: this.dateFilter,
    });
  }
}
